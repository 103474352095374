<template>
  <div v-if="breadcrumbs?.length > 0" class="app-breadcrumbs">
    <div class="app-breadcrumbs__content">
      <SlotLink class="app-breadcrumbs__home" url="home">
        <AppIcon icon="home" :is-raw="true" />
      </SlotLink>
      <nav class="app-breadcrumb__crumbs">
        <ul>
          <li v-for="(crumb, i) in breadcrumbs" :key="i">
            <nuxt-link :to="crumb.path">
              {{ crumb.name }}
            </nuxt-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
const route = useRoute();

const breadcrumbs = computed(() => {
    const { fullPath } = route;

    const params = fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/');

    /* Remove empty params */
    const cleanParams = params.filter((e) => e);

    const crumbs = [];
    let path = '';

    cleanParams.forEach((param) => {
        path = `${path}/${param}`;

        const words = removeGetParams(param).split('-');
        words.map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');

        const crumbObj = {
            path,
            name: words.map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' '),
        };

        crumbs.push(crumbObj);
    });
    return crumbs;
});

</script>

<style lang="scss">
.app-breadcrumbs {
    width: 100%;
    max-width: var(--grid-width);
    padding: 0 var(--page-padding);
    margin: 0 auto;
    margin-bottom: 34px;

    &.has-padding {
        padding-top: 190px;
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
    }

    &.has-small-padding {
        @include fluid('padding-top', 0px, 90px);
    }

    @include tablet-portrait {
        display: none;
    }
}

.app-breadcrumbs__content {
    @include typo-font('italic');
    @include typo-size('a');

    display: flex;
    align-items: center;
    color: $C-PRIMARY;
}

.app-breadcrumbs__home {
    margin-right: 23px;

    .app-icon {
        width: 24px;
        height: 24px;
    }
}

.app-breadcrumb__crumbs {
    ul {
        display: flex;
    }

    li {
        display: flex;
        margin-right: 23px;
        white-space: nowrap;
    }

    li:hover {
        color: $C-SECONDARY;
    }

    li:first-child {
        &:before {
            content: none;
        }
    }

    li:last-child {
        color: $C-GREEN_DARK;
    }

    li::before {
        margin-right: 23px;
        color: $C_GREEN_DARK;
        content: '●';
        transform: scale(0.6);
    }
}
</style>
